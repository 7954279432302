<template>
  <div id="senior-member-bulk-payment">
    <v-overlay :value="ProgressLoadingFlag">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-card>
      <v-card-title class="text-h5">
        <h2>{{ PageTitle }}</h2>
      </v-card-title>

      <v-card-subtitle class="text-h5">
        <h4>
          {{ PageDescription }}
        </h4>
      </v-card-subtitle>

      <v-card-text>
        <div class="d-flex justify-content-center mb-3" v-if="LoadingFlag">
          <b-spinner
            variant="primary"
            type="grow"
            label="Loading..."
          ></b-spinner>
        </div>
        <v-container v-if="StartupLoadingFlag">
          <v-row wrap>
            <v-col cols="12" md="4" v-for="n in 6" :key="n">
              <v-skeleton-loader
                v-bind="attrs"
                type="paragraph"
              ></v-skeleton-loader>
            </v-col>
          </v-row>
        </v-container>
        <v-container v-if="!StartupLoadingFlag">
          <v-row wrap>
            <v-col align="center" cols="12" sm="12" md="12">
              <v-btn
                @click.prevent="refreshPageData"
                color="#4285f4"
                elevation="30"
                shaped
                tile
                large
                class="
                  btn btn-primary
                  font-size-h6
                  px-15
                  py-4
                  my-3
                  mr-3
                  white--text
                "
              >
                Refresh
              </v-btn>
            </v-col>
          </v-row>
          <v-form ref="form2" v-model="valid1" lazy-validation>
            <v-row wrap>
              <v-col cols="12" md="3">
                <h4 class="text-primary">LO</h4>
                <h4>{{ Lom.LomName }}</h4>
                <h4>{{ Lom.ZoneName }}</h4>
              </v-col>
              <v-col cols="12" md="3">
                <h4 class="text-primary">President details</h4>
                <h4>{{ Presidentmember.LomPresidentFullName }}</h4>
                <h4>{{ Presidentmember.LomPresidentMembershipId }}</h4>
              </v-col>
              <v-col cols="12" md="3">
                <h4 class="text-primary">President Photo</h4>
                <v-img
                  :src="Presidentmember.MemberImagePath"
                  :alt="Presidentmember.LomPresidentFullName"
                  lazy-src="/no-image-available.png"
                  max-width="100"
                  max-height="100"
                >
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                      <v-progress-circular
                        indeterminate
                        color="grey lighten-5"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </v-col>
            </v-row>
            <v-row wrap>
              <v-col cols="12" sm="6" md="3">
                <h4 class="text-primary">
                  <span class="text-danger">*</span> Mobile Number
                </h4>
                <v-text-field
                  v-model="MobileNo"
                  :rules="MobileNoRules"
                  :counter="10"
                  v-mask="'##########'"
                  type="number"
                  hint="Enter your email id"
                  dense
                  outlined
                ></v-text-field>
                <!-- <h4>{{ Member.MobileNo }}</h4> -->
              </v-col>
              <v-col cols="12" sm="6" md="3">
                <h4 class="text-primary">
                  <span class="text-danger">*</span> Email Id
                </h4>
                <v-text-field
                  v-model="EmailId"
                  :rules="EmailIdRules"
                  type="email"
                  hint="Enter your email id"
                  dense
                  outlined
                ></v-text-field>
                <!-- <h4>{{ Member.EmailId }}</h4> -->
              </v-col>
            </v-row>
            <v-row wrap>
              <v-col align="center" cols="12" md="12">
                <h3 class="text-primary">List of regular subscriptions</h3>
              </v-col>
              <v-col align="center" cols="12" md="12">
                <br />
                <v-data-table
                  class="elevation-1"
                  v-model="selected"
                  :loading="SearchFlag"
                  loading-text="Loading... Please wait"
                  :headers="tableColumns1"
                  :items="rows"
                  :search="search"
                  :item-key="tableOptions1.ItemKey"
                  :single-select="tableOptions1.SingleSelectFlag"
                  :items-per-page="tableOptions1.ItemsPerPage"
                  :footer-props="tableOptions1.FooterProps"
                  :hide-default-footer="tableOptions1.HideDefaultFooter"
                >
                  <template v-slot:item.Qty="{ item }">
                    <v-text-field
                      v-model="item.Qty"
                      v-on="calculateTotal()"
                      type="number"
                      min="0"
                      :rules="QtyRules"
                    ></v-text-field>
                  </template>
                  <template v-slot:item.SubTotal="{ item }">
                    {{ item.SubTotal }}
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
            <v-row v-if="rows.length > 0">
              <v-col align="center" cols="12" md="12">
                <h3 class="text-primary">
                  Total Amount : Rs. {{ TotalAmount }}
                </h3>
                <v-btn
                  :disabled="!valid1"
                  @click.prevent="confirmAlert"
                  :loading="SubmitFlag"
                  color="#8950FC"
                  elevation="30"
                  shaped
                  tile
                  large
                  class="
                    btn btn-primary
                    font-size-h6
                    px-15
                    py-4
                    my-3
                    mr-3
                    white--text
                  "
                >
                  Submit
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
        <br /><br />
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import common from "@/view/Common.vue";
import companyConfig from "@/company_config.json";
import Swal from "sweetalert2";

export default {
  mixins: [common],
  components: {},
  data() {
    return {
      valid1: true,
      valid2: true,
      LoadingFlag: false,
      StartupLoadingFlag: false,
      ProgressLoadingFlag: false,
      SubmitFlag: false,
      SearchFlag: false,
      NotesFlag: false,
      rows: [],
      tableColumns1: [],
      tableOptions1: [],
      selected: [],
      search: "",

      Lom: {},

      Presidentmember: {},

      TotalAmount: 0,

      TotalAmount: 0,

      Member: {},
      Order: {},
      MemberImageFlag: false,

      MobileNo: "",
      MobileNoRules: [(v) => !!v || "Mobile No is required"],

      EmailId: "",
      EmailIdRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],

      QtyRules: [
        (v) => Number.isInteger(Number(v)) || "Nos is invalid",
        (v) => v >= 0 || "Number must be greater than zero",
      ],

      GstNumber: "",
      GstNumberRules: [(v) => !!v || "GST number is required"],

      LomCode: "",
      MemberTypeId: 1,
      EventId: 1,

      Order: {
        back_url: "",
        customer_name: "",
        customer_email_id: "",
        customer_mobile_no: "",
        rzp_action: "#",
        rzp_account_id: "",
        rzp_key: "bdgzbd",
        rzp_amount: "",
        rzp_order_id: "",
        rzp_buttontext: "Pay now",
        rzp_name: "JCI India Foundation",
        rzp_description: "",
        rzp_image: "",
        rzp_color: "#0096d5",
      },

      PageInfo: {},
      PageTitle: "",
      PageDescription: "",
      CurrentYearId: 0,
      JciYearCode: "",
      YearName: "",
      NextYearCode: "",
      NextYearName: "",
    };
  },
  computed: {},
  mounted() {},
  watch: {
    PageInfo: function () {
      console.log("watch PageInfo");
      this.StartupLoadingFlag = false;
      var tr = this.PageInfo;
      console.log({ tr });
      var n1 = Object.keys(tr).length;
      console.log({ n1 });
      if (n1 > 0) {
        this.PageTitle = tr.PageTitle;
        this.PageDescription = tr.PageDescription;

        var CurrentJciYearId = parseInt(tr.CurrentJciYearId);
        console.log({ CurrentJciYearId });
        this.JciYearCode = CurrentJciYearId;

        var YearName = parseInt(tr.CurrentJciYearName);
        console.log({ YearName });
        this.YearName = YearName;

        var NextJciYearId = parseInt(tr.NextJciYearId);
        console.log({ NextJciYearId });
        this.NextYearCode = NextJciYearId;

        var NextJciYearName = parseInt(tr.NextJciYearName);
        console.log({ NextJciYearName });
        this.NextYearName = NextJciYearName;

        this.CurrentYearId = CurrentJciYearId;

        var LomId = this.$session.get("LomId");
        LomId = LomId == (null || undefined) ? "" : LomId;
        this.LomCode = LomId;

        var ZoneCode = this.$session.get("ZoneId");
        ZoneCode = ZoneCode == (null || undefined) ? "" : ZoneCode;
        console.log("ZoneCode=" + ZoneCode);
        this.ZoneCode = ZoneCode;

        this.pageData();
      }
    },
  },
  methods: {
    refreshPageData() {
      console.log("refreshPageData called");
      this.StartupLoadingFlag = true;
      var url1 = "api/menu-details/show";
      var condition1 = {
        UserInterface: 1,
        Module: "senior_members",
        Action: "create",
      };
      this.getPageDetails("PageInfo", {}, url1, condition1);
    },
    pageData() {
      console.log("pageData called");
      this.getPresidentMemberDetails();
      this.getTableRecords();
    },
    // code 1
    validate() {
      if (this.$refs.form2.validate()) {
        this.snackbar = true;
      }
    },
    resetValidation() {
      this.$refs.form2.resetValidation();
    },
    getPresidentMemberDetails() {
      console.log("getPresidentMemberDetails is called");

      var LomId = this.LomCode;
      console.log("LomId=" + LomId);

      if (LomId != "") {
        this.Presidentmember = {};

        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/lom/show";
        var upload = {
          UserInterface: 2,
          Lom: LomId,
        };
        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        const thisIns = this;
        var output = "";
        var records = "";
        var successTxt = "";
        var errorTxt = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            thisIns.StartupLoadingFlag = false;

            output = response.data.output;
            flag = response.data.flag;
            output = response.data.output;
            records = response.data.records;
            console.log("output=" + output + ", flag=" + flag);
            successTxt = response.data.success;
            errorTxt = response.data.error;
            console.log("successTxt=" + successTxt + ", errorTxt=" + errorTxt);

            if (flag == 1) {
              var Presidentmember = records.President;
              thisIns.Lom = records;
              thisIns.Presidentmember = Presidentmember;
              thisIns.MobileNo = Presidentmember.LomPresidentMobileNo;
              thisIns.EmailId = Presidentmember.LomPresidentEmailid;
            } else {
              thisIns.toast("error", output);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            thisIns.StartupLoadingFlag = false;
          });
      } else {
        var message = "";
        if (LomId == "") {
          message += "Your session is expired. Kindly login again. ";
        }
        this.toast("error", message);
      }
    },
    getTableRecords() {
      console.log("getTableRecords is called.");

      var server_url = companyConfig.apiURL;
      var token = this.$session.get("token");
      token = token == (null || undefined) ? 0 : token;
      var add_url = server_url + "api/sma/payment/plans/lists";
      var upload = {
        UserInterface: 1,
      };
      console.log("upload=" + JSON.stringify(upload));
      console.log(
        "server_url=" + server_url + ", token=" + token + ", add_url=" + add_url
      );

      const thisIns = this;
      var output = "";
      var records = "";
      var successTxt = "";
      var errorTxt = "";
      var flag = 0;

      this.$http({
        url: add_url,
        method: "POST",
        data: upload,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        onUploadProgress: function (progressEvent) {
          this.uploadPercentage = parseInt(
            Math.round((progressEvent.loaded / progressEvent.total) * 100)
          );
        }.bind(this),
      })
        .then(function (response) {
          console.log("response=" + JSON.stringify(response));

          // thisIns.pageLoadingOff()
          thisIns.SearchFlag = false;

          output = response.data.output;
          flag = response.data.flag;
          output = response.data.output;
          records = response.data.records;
          console.log("output=" + output + ", flag=" + flag);
          successTxt = response.data.success;
          errorTxt = response.data.error;
          console.log("successTxt=" + successTxt + ", errorTxt=" + errorTxt);

          if (flag == 1) {
            thisIns.tableColumns1 = records.TableHeader;
            thisIns.tableOptions1 = records.TableOptions;
            thisIns.rows = records.TableData;
            thisIns.toast("success", output);
          } else {
            thisIns.toast("error", output);
          }
        })
        .catch(function (error) {
          console.log("error=" + error);
          this.SearchFlag = false;
        });
    },
    calculateTotal() {
      console.log("calculateTotal called");
      var n1 = this.rows.length;
      var totalAmount = 0;
      // console.log("n1=" + n1 + ", rows=" + JSON.stringify(this.rows));
      for (var i = 0; i < n1; i++) {
        var qty = this.rows[i]["Qty"];
        var amount = this.rows[i]["Amount"];
        var TaxAmount = this.rows[i]["TaxAmount"];
        console.log(
          "i=" +
            i +
            ", qty=" +
            qty +
            ", amount=" +
            amount +
            ", TaxAmount=" +
            TaxAmount
        );
        TaxAmount = !isNaN(TaxAmount) ? TaxAmount : 0;
        qty = !isNaN(qty) ? (qty >= 0 ? qty : 0) : 0;
        amount = !isNaN(amount) ? amount + TaxAmount : 0;
        console.log("qty=" + qty + ", amount=" + amount);
        var subTotal = parseFloat(qty) * parseFloat(amount);
        this.rows[i]["SubTotal"] = isNaN(subTotal)
          ? 0
          : this.getDecimalNumber(subTotal, 2);
      }
      var totalAmount = this.rows.reduce(function (sum, line) {
        var lineTotal = parseFloat(line.SubTotal);
        console.log("lineTotal=" + lineTotal);
        if (!isNaN(lineTotal)) {
          return sum + lineTotal;
        }
      }, 0);
      console.log("totalAmount=" + totalAmount);
      this.TotalAmount = isNaN(totalAmount)
        ? 0
        : this.getDecimalNumber(totalAmount, 2);
    },
    confirmAlert() {
      var validate1 = this.$refs.form2.validate();
      var TotalAmount = this.TotalAmount;
      var Presidentmember = this.Presidentmember;
      console.log(
        "validate1=" +
          validate1 +
          ", Presidentmember=" +
          JSON.stringify(Presidentmember)
      );

      if (validate1 && TotalAmount > 0) {
        var htmlTxt = "";
        // htmlTxt += "<b>Please verify all the information before proceeding</b>";
        htmlTxt += "<br/>Mobile no. <b>" + this.MobileNo + "</b>";
        htmlTxt += "<br/>Email id <b>" + this.EmailId + "</b>";
        htmlTxt += "<br/><b>Payment details</b>";
        htmlTxt +=
          "<br/><table><tr><th>Payment option</th><th>No of members</th><th>PSubscription Fees</th></tr>";
        this.rows.forEach((element) => {
          if (element.SubTotal > 0) {
            htmlTxt +=
              "<tr><td>" +
              element.DescriptionTxt +
              "</td><td>" +
              element.Qty +
              "</td><td>" +
              element.SubTotal +
              "</td></tr>";
          }
        });
        htmlTxt += "</table>";
        htmlTxt += "<br/>Total amount <b>" + TotalAmount + "</b>";
        htmlTxt += "<br/><br/>Do you want to continue?";
        Swal.fire({
          title: "Please verify all the information before proceeding",
          text: "",
          icon: "warning",
          html: htmlTxt,
          showCancelButton: true,
          confirmButtonText: `Continue`,
          confirmButtonColor: "#3085d6",
          cancelButtonText: `Cancel`,
          cancelButtonColor: "#d33",
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            this.submitForm();
            // this.makePayment();
          } else {
            this.sweetAlert("error", "You cancelled the process", true);
          }
        });
      } else {
        var message = "";
        if (!validate1) {
          message += "Kindly fill the required fields. ";
        }
        if (TotalAmount == 0) {
          message += "Minimum one nos required to continue. ";
        }
        this.sweetAlert("error", message, false);
      }
    },
    submitForm() {
      console.log("submitForm is called");

      var validate1 = this.$refs.form2.validate();
      var Presidentmember = this.Presidentmember;
      console.log(
        "validate1=" +
          validate1 +
          ", Presidentmember=" +
          JSON.stringify(Presidentmember)
      );

      var MemberId = Presidentmember.MemberId;
      MemberId = MemberId == (null || undefined) ? "" : MemberId;
      console.log("MemberId=" + MemberId);

      var participants = [];
      participants.push(Presidentmember);

      var TotalCount = participants.length;
      console.log("TotalCount=" + TotalCount);

      var TotalAmount = this.TotalAmount;

      if (validate1 && TotalCount > 0 && TotalAmount > 0) {
        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/senior/members/payment/create";
        var upload = {
          UserInterface: 1,
          Year: this.JciYearCode,
          Zone: this.ZoneCode,
          Lom: this.LomCode,
          PaymentDetails: this.rows,
          MobileNo: this.MobileNo,
          EmailId: this.EmailId,
          Member: Presidentmember,
          TotalAmount: TotalAmount,
        };
        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        this.SubmitFlag = true;
        this.ProgressLoadingFlag = true;

        // this.$session.set("checkout", upload);
        // this.checkout();

        const thisIns = this;
        var output = "";
        var records = "";
        var successTxt = "";
        var errorTxt = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            // thisIns.pageLoadingOff()
            thisIns.SubmitFlag = false;
            thisIns.ProgressLoadingFlag = false;

            output = response.data.output;
            flag = response.data.flag;
            output = response.data.output;
            records = response.data.records;
            console.log("output=" + output + ", flag=" + flag);
            successTxt = response.data.success;
            errorTxt = response.data.error;
            console.log("successTxt=" + successTxt + ", errorTxt=" + errorTxt);

            if (flag == 1) {
              /*
              thisIns.sweetAlert(
                "info",
                "You will be redirected to checkout page shortly",
                true
              );
              */
              thisIns.Order = records.Order;
              thisIns.makePayment();
            } else {
              // thisIns.toast("error", output);
              // thisIns.Order = records.Order;
              // thisIns.makePayment();
              thisIns.sweetAlert("info", output, false);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            thisIns.SubmitFlag = false;
            thisIns.ProgressLoadingFlag = false;
          });
      } else {
        var message = "";
        if (!validate1) {
          message += "Kindly fill the required fields. ";
        }
        if (TotalCount == 0) {
          message += "Member id is empty. Kindly refresh. ";
        }
        if (TotalAmount == 0) {
          message += "Kindly select a plan. ";
        }
        this.sweetAlert("error", message, false);
      }
    },
    makePayment() {
      console.log("makePayment");
      // ev.preventDefault();
      var order = this.Order;
      const thisIns = this;
      var rzp1 = new Razorpay({
        key: order.rzp_key,
        account_id: order.rzp_account_id,
        order_id: order.rzp_order_id,
        amount: order.rzp_amount,
        name: order.rzp_name,
        currency: "INR",
        description: order.rzp_description,
        image: order.rzp_image,
        theme: {
          color: order.rzp_color,
        },
        prefill: {
          name: order.customer_name,
          email: order.customer_email_id,
          contact: order.customer_mobile_no,
        },
        notes: {
          address: "",
        },
        // callback_url: "http://domain.com/#/about/" + this.order_id,
        redirect: false,
        handler: function (response) {
          console.log("razorpay_payment_id=" + response.razorpay_payment_id);
          thisIns.SubmitFlag = true;
          thisIns.sweetAlert("success", "success!", true);
          // thisIns.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");
          thisIns.pageRedirect({}, "/order/success");
        },
      });
      rzp1.open();
    },
  },
  beforeMount() {
    this.refreshPageData();
  },
};
</script>
<style lang="scss">
/* This is for documentation purposes and will not be needed in your application */
#senior-member-bulk-payment {
  @import url(https://fonts.googleapis.com/css?family=Lato:700);
  $color: #8950fc;
  .cards {
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0);
    flex: 1;
    margin: 8px;
    padding: 10px;
    height: 500px;
    width: 225px;
    position: relative;
    text-align: center;
    transition: all 0.5s ease-in-out;
    border: solid 1px #4f1990;
  }
  .cards-box {
    height: 550px;
    margin-top: 10px;
  }
  .cards.popular {
    margin-top: -10px;
    height: 522px;
    padding: 20px;
    width: 225px;
  }
  .cards.popular .cards-title-bar .cards-title {
    color: $color;
    font-size: 34px;
  }
  .cards.popular .cards-price {
    margin: 40px 0 -28px 0;
  }
  .cards.popular .cards-price .cards-price-amount {
    color: $color;
    font-size: 52px;
  }
  .cards.popular .cards-action .cards-action-button {
    background-color: $color;
    color: #fff;
    font-size: 16px;
    padding: 15px;
    height: 60px;
  }
  .cards.popular .cards-action .cards-action-button:hover {
    background-color: $color;
    font-size: 20px;
  }
  .cards:hover {
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.6);
  }
  .cards-ribbon {
    position: absolute;
    overflow: hidden;
    top: -10px;
    left: -10px;
    width: 114px;
    height: 112px;
  }
  .cards-ribbon span {
    position: absolute;
    z-index: 1;
    display: block;
    width: 160px;
    padding: 10px 0;
    background-color: #3498db;
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
    color: #fff;
    font-size: 12px;
    text-transform: uppercase;
    text-align: center;
    left: -35px;
    top: 25px;
    transform: rotate(-45deg);
  }
  .cards-ribbon::before,
  .cards-ribbon::after {
    position: absolute;
    content: "";
    display: block;
    border: 5px solid #2980b9;
    border-top-color: transparent;
    border-left-color: transparent;
  }
  .cards-ribbon::before {
    top: 0;
    right: 0;
  }
  .cards-ribbon::after {
    bottom: 0;
    left: 0;
  }
  .cards-title-bar .cards-title {
    color: rgba(0, 0, 0, 0.3);
    font-size: 34px;
    text-transform: uppercase;
  }
  .cards-title-bar .cards-subtitle {
    color: rgba(0, 0, 0, 0.6);
    font-size: 16px;
  }
  .cards-price {
    margin: 40px 0 -28px 0;
  }
  .cards-price .cards-price-amount {
    font-size: 46px;
  }
  .cards-price .cards-price-amount .cards-price-sypol {
    font-size: 17px;
    display: inline-block;
    margin-left: -20px;
    width: 10px;
    color: rgba(0, 0, 0, 0.3);
  }
  .cards-price .cards-price-amount .cards-price-validity {
    color: rgba(0, 0, 0, 0.3);
    display: block;
    font-size: 12px;
    text-transform: uppercase;
  }
  .cards-description .cards-description-content-bar {
    display: block;
    list-style: none;
    margin: 40px 0 25px 0;
    padding: 0;
  }
  .cards-description .cards-description-content {
    color: rgba(0, 0, 0, 0.6);
    font-size: 14px;
    margin: 0 0 10px;
  }
  .cards-description .cards-description-content::before {
    content: "♦";
    padding: 0 5px 0 0;
    color: rgba(0, 0, 0, 0.5);
    font-size: 14px;
  }
  .cards-action .cards-action-button {
    background: transparent;
    border: 2px solid $color;
    border-radius: 30px;
    color: $color;
    cursor: pointer;
    display: block;
    font-size: 15px;
    font-weight: bold;
    padding: 20px;
    width: 100%;
    height: 60px;
    text-transform: uppercase;
    transition: all 0.3s ease-in-out;
  }
  .cards-action .cards-action-button:hover {
    background-color: $color;
    box-shadow: 0 2px 4px #4f1990;
    color: #fff;
    font-size: 17px;
  }
  .v-data-table > .v-data-table__wrapper > table > thead > tr > th {
    font-size: 16px;
  }
  .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
    font-size: 15px;
  }
}
</style>